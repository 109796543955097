import React from 'react';
import Helmet from 'react-helmet';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import 'sanitize.css';
import '../../styles/main.css';

export default ({ children, config, hero }) => (
    <div>

        <Helmet defaultTitle={'Headless WP With Gatsby'} titleTemplate={`%s | Headless WP With Gatsby`} />

        <Header name={'Headless WP With Gatsby'} description={'Testing out Headless WP With Gatsby'} />

        <main className="main" role="main" id="main">

            {children}
        
        </main>

        <Footer name={'Headless WP With Gatsby'} />

    </div>
);
