import React, { Component } from 'react';
import Link from 'gatsby-link';
import Img from 'gatsby-image';

import './PostFilter.css';

export default class PostFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredPosts: [],
            postQuery: '',
        };
    }

    componentWillMount() {
        this.filterPosts('');
    }

    filterPosts = (q) => {
        const { posts } = this.props;
        this.setState({
            postQuery: q,
            filteredPosts: posts.filter(p => p.post.title && p.post.title.toLowerCase().includes(q.toLowerCase())),
        });
    }

    render() {
        const { filteredPosts, postQuery } = this.state;
        return(
            <div className="post-filter">
                <h2> Search posts </h2>
                <input value={postQuery} onChange={e => this.filterPosts(e.target.value)} />
                <div className="posts">
                    {filteredPosts.map(p => (
                        <div className="post">
                            <Link to={p.post.slug}>
                                <span className="post-title">{p.post.title}</span>
                                {p.post.featured_media && p.post.featured_media.localFile ?
                                    <Img
                                        sizes={p.post.featured_media.localFile.childImageSharp.sizes}
                                        title={p.post.title}
                                        fadeIn
                                    /> :
                                    <img src={(p.post.featured_media && p.post.featured_media.source_url)} />}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
